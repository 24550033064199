import React, { useState } from "react"
import { Input, message } from "antd"
import * as style from "./style.module.scss"

const Home = () => {
  const [email, setEmail] = useState()
  const [loading, setLoading] = useState(false)

  const subscribe = async () => {
    const validEmail = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )

    if (validEmail) {
      let formData = new FormData()
      formData.append("EMAIL", email)
      setLoading(true)
      await fetch(
        "https://raynetwork.us20.list-manage.com/subscribe/post?u=630230660ec54c50b0c34762c&id=844a6bbde0",
        {
          method: "post",
          mode: "no-cors",
          body: formData,
        }
      )
      setLoading(false)
      setEmail()
      message.success("You have successfully subscribed!")
    } else {
      message.error("Please enter a valid email address.")
    }
  }

  return (
    <div className="overflow-hidden">
      <div className="ray__block pt-5">
        <div className={style.promo}>
          <img src="/resources/partials/1.png" alt="" className={style.img} />
          <div className={style.content}>
            <h1 className="max-width-800 lh-1 mb-5">
              <strong className="bolder">GraphQL APIs<br /> for Cardano<br /> decentralized apps</strong>
            </h1>
            <p className="max-width-900 font-size-18 mb-5">The RayGraph is ready to let you deploy graphql-based APIs to access historical data previously indexed by subgraph protocol. By combining with <a href="https://github.com/ray-network/cardano-web3.js" target="_blank" rel="noopener noreferrer"><u>CardanoWeb3.js</u></a> library, developers can build dapps with powerful new features for solving the world's greatest challenges.</p>
            <h6><strong>Subscribe to get early access</strong></h6>
            <div className="max-width-400">
              <Input.Search
                placeholder="Enter your email"
                allowClear
                enterButton="Subscribe"
                size="large"
                value={email}
                type="email"
                onSearch={() => subscribe()}
                onChange={(e) => setEmail(e.target.value)}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
